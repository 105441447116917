import * as React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { useLocation } from "@reach/router"

import Cta from "../../components/home/CallToAction"
import App from "../../components/features/App"
import Box from "../../components/features/Box"
import DeliveryService from "../../components/features/DeliveryService"
import Accordion from "../../components/AccordionBlock"

import { Helmet } from "react-helmet"

import { deDE } from "../../lang/de-DE"

export default function Home() {
  const location = useLocation()

  let country = "DE"
  let lang = "de-DE"
  let txt = deDE

  return (
    <Layout location={country} path={location.pathname} language={lang}>
      <Seo
        title={txt.SEO.title}
        description={txt.SEO.description}
        lang={lang}
      />
      <Helmet>
        <link
          rel="alternate"
          hreflang="de-DE"
          href="https://dorygo.com/de-de/"
        />
        <link
          rel="canonical"
          hreflang="de-CH"
          href="https://dorygo.ch/ch-de/"
        />
      </Helmet>
      
    
      <DeliveryService txt={txt.features.DeliveryService} />
      <App txt={txt.features.App} />
      <Box txt={txt.features.Box} />
      <Cta txt={txt.features.CallToAction} country={country} language={lang}/>
      <Accordion input ={txt.features.FAQ}/>  
    </Layout>
  )
}
